<template>
    <div
            v-ripple="{ class: `primary--text` }"
            :key="title"
            @click="$emit('click')"
            class="card-button"
            :class="cardClass"
    >
        <div class="d-flex flex-column">
            <div class="d-flex align-center mb-1 mb-sm-3 card-button__header" style="min-height: 32px">
              <v-avatar
                v-if="loading"
                size="32"
                color="primary"
                class="mr-2"
              >
                <v-progress-circular size="20" indeterminate color="white"/>
              </v-avatar>
                <v-avatar
                        v-else-if="icon"
                        size="32"
                        color="primary"
                        class="mr-2"
                >
                    <v-icon dark dense>{{ icon }}</v-icon>
<!--                  <v-progress-circular size="16" indeterminate color="white"/>-->

                </v-avatar>
                <h4 class="flex-grow-1 card-button__title">{{ title }}</h4>
            </div>
            <div
                    v-if="description"
                    class="card-button__description"
            >
                {{ description }}
            </div>
        </div>
        <v-spacer/>
        <v-icon>mdi-chevron-right</v-icon>
    </div>
</template>

<script>
export default {
  name: 'CardButton',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
    },
    block: {
      type: Boolean,
      required: false,
    },
    dense: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    cardClass() {
      return (this.$vuetify.breakpoint.smAndUp ? '' : 'card-button-mobile') + ' ' +
        (this.block ? 'block' : '') + ' ' + (this.dense ? 'dense' : '') + ' ' +
        (this.$vuetify.theme.isDark ? 'theme--dark' : 'theme--light');
    },
  },
};
</script>

<style lang="scss" scoped>
.card-button {
  padding: 16px 4px 16px 20px;
  //min-height: 126px;
  height: inherit;
  display: flex;
  //flex-direction: column;
  border-radius: 5px;
  width: 270px;
  min-width: 270px;
  cursor: pointer;
}

.card-button.block {
  width: 100%;
  min-height: inherit;
}

.card-button.dense {
  padding: 12px 4px 12px 20px;
}

.card-button.dense .card-button__header {
    margin-bottom: 4px !important;
}

.card-button * {
  user-select: none;
}

.card-button-mobile {
  width: 100%;
  padding: 12px 8px 8px 12px !important;
  min-height: inherit;
}

.card-button .card-button__title {
  line-height: 1.2;
}

.theme--light.card-button .card-button__title {
  color: #2d2c33;
}

.theme--light.card-button .card-button__description {
  color: #2d2c33;
}

.theme--light.card-button {
  border: thin solid rgba(0, 0, 0, 0.12);
}

.theme--dark.card-button {
  background-color: #1E1E1E;
  color: #FFFFFF;
  border: thin solid rgba(255, 255, 255, 0.12);
}

.theme--light.card-button:hover {
  background-color: #d8e8f7;
}

.theme--dark.card-button:hover {
  background-color: #22252a;
}
</style>
