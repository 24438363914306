<template>
    <router-link
            :to="$attrs.to"
            class="card-link"
            :class="block ? 'block' : ''"
            :style="$vuetify.breakpoint.smAndUp ? '' : 'width: calc(100% - 8px);'"
    >
        <CardButton
                v-bind="$attrs"
                v-on="$listeners"
                :style="$vuetify.breakpoint.smAndUp ? 'height: 100%' : ''"
                :block="block"
                :dense="dense"
                :loading="loading"
        />
    </router-link>
</template>

<script>
import CardButton from "./CardButton.vue";

export default {
  name: 'CardLink',
  components: {CardButton},
  props: ['block', 'dense', 'loading'],
};
</script>

<style scoped>
.card-link.block {
    width: calc(100% - 8px);
}

.card-link:link {
    text-decoration: none;
}

.card-link:visited {
    text-decoration: none;
}

.card-link:hover {
    text-decoration: none;
}

.card-link:active {
    text-decoration: none;
}
</style>
